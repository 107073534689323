<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">库存设备列表</div>
        
        <TableComponent :headers="headers" :tableObjet="tableObjet" :psize="5" :height="750">
            <template #deposit="item">
                <div class="red">
                    {{ item.row.deposit }}
                </div>
            </template>
            <template #price="item">
                <el-tag class="rebeccapurple">
                    {{ item.row.price }}
                </el-tag>
            </template>
            <template #d15_price="item">
                <el-tag class="rebeccapurple">
                    {{ item.row.d15_price }}
                </el-tag>
            </template>
            <template #qrcode="item">


                <img :src="'https://api.pwmqr.com/qrcode/create/?url=https://jimove.jimoveebikes.com/' + item.row.device_number"
                    alt="" width="100" @click="fangda(item.row)">


            </template>
            <template #state="item">
                <el-tag>{{ getState(item.row.state) }}</el-tag>
            </template>
            <template #lock_state="item">

                <el-switch :value="item.row.lock_state == 1 ? true : false" disabled></el-switch>
            </template>
            <template #type="item">
                {{ getType(item.row.type) }}
            </template>
            <!-- <template #active_state="item">
                    <el-tag>{{item.row.active_state?'已激活':'未激活'}}</el-tag>
            </template> -->
            <!-- <template #active_code="item">
                <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data='+item.row.active_code" alt="" width="100">
            </template> -->

            <template #print="item">
                <el-button @click="addToPrints(item.row)" circle icon="Printer">

                </el-button>
            </template>
           
        </TableComponent>

      
    </div>

   
</template>

<script setup >
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useDeletedDevice } from "@/use/leaseitems"
import print from "print-js"
// 导入 jsPDF 库
import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import { reactive, inject, ref, watch } from "vue"
import { updateLeaseItem, moveDeviceForId, deleteItemForId } from "@/api/leaseItem"
import {useGetAdminListForOption} from "@/use/admin"

import { getItemModelList } from "@/api/itemModel"

import { getBillingRulesOptions } from "@/api/billingrule";
import axios from 'axios';
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const dialogFormVisible = ref(false)
const zhuanyidialogFormVisible = ref(false)
const store_id = ref()
const { headers, tableObjet, search, searchData } = useDeletedDevice();
const leaseItem = ref()
const prints = ref([])
const w = ref(180)
const itemModelId = ref("")
const billingRuleId = ref("")
const itemModelList = ref([])
const billingRuleList = ref([])
const { admimOption } = useGetAdminListForOption(3);
const deleteItem = (i) => {
    deleteItemForId({ id: i.id }).then(res => {
        if (res.code != 200) {
            ElMessage.error(res.msg)
        } else {
            ElMessage.success("删除成功")
        }
        search()
    })
}
const addToPrints = (i) => {
    const isIn = prints.value.indexOf(i)
    if (isIn < 0) prints.value.push(i)
}
const eeee=(i)=>{
    axios.get("http:///127.0.0.1/index.php/index/eeee?device_number="+i.device_number).then(res=>{
       ElMessage.info(res.data.data[0].message)
    })
}
const printAll = (i) => {
    if (prints.value.length == 0) {
        ElMessage.info("请先选择要打印的二维码")
        return
    }
    w.value = i
    ElMessage.info("正在启动打印")
    let text = i < 200 ? "小尺寸二维码" : "大尺寸二维码"

    window.alert("你选择的是" + text + "打印时先选择好：纸张尺寸和打印边距")
    setTimeout(() => {
        print({
            printable: 'qrcodes',
            type: "html",
            style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

        })
    }, 1000);
}

watch(store_id, i => {
    billingRuleId.value = ""

    getBillingRulesOptions(i).then(res => {
        if (res.code == 200) {
            billingRuleList.value = res.data
        }
    })
    itemModelId.value = ""
    getItemModelList({ store_id: i }).then(res => {
        if (res.code == 200) {
            itemModelList.value = res.data.data.map(i => {
                return {
                    label: i.model,
                    value: i.id
                }
            })
        }
    })
})
const dayin = () => {

    // const LODOP = getLodop()

    // LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
    // LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
    // LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
    //LODOP.PREVIEW();
    // LODOP.PRINT();
    // LODOP.PRINT();
    // return
    print({
        printable: 'qrcode',
        type: "html",
        style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

    })
    return
    // print the html string

    // 获取要打印的节点
    const node = document.getElementById('qrcode');

    // 创建一个新的 jsPDF 实例
    const doc = new jsPDF();

    // 将节点的 HTML 内容添加到 PDF 中
    doc.html(node, {
        callback: function () {
            // 调用浏览器的打印对话框
            window.print();
        }
    });
    //window.print()
    // print the dom element
    //const  content= document.getElementById('qrcode')
    //callPrinter(`<img src="https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/080265295"/>22`)
}
const getState = (state) => {

    const states = {
        0: "闲置",
        1: "租赁中"
    };
    return states[state]
}
const fangda = (item) => {
    dialogFormVisible.value = true
    leaseItem.value = item
}
const getType = (type) => {

    const types = {
        1: "按周",
        2: "按月"
    };
    return types[type]
}
const moveDevice = () => {
    moveDeviceForId({
        store_id: store_id.value,
        lease_item_id: leaseItem.value.id,
        billingRuleId:billingRuleId.value,
        itemModelId:itemModelId.value
    }).then(res => {
        if (res.code == 200) {
            ElMessage.success("出库成功!")
            zhuanyidialogFormVisible.value = false
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const handleEdit = (index, item) => {
    // if (item.state != 0) {
    //     ElMessage.error("只有闲置中的物品才能修改!")
    //     return
    // }
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",

            value: item.name,
            canEdit: false,
            type: "text"
        },
        device_number: {
            label: "设备号",

            value: item.device_number,
            canEdit: true,
            type: "text"
        },
        item_number: {
            label: "物品编号",

            value: item.item_number,
            canEdit: true,
            type: "text"
        },

        device_car_number: {
            label: "车架号",

            value: item.device_car_number,
            canEdit: true,
            type: "text"
        },
        lock_state: {
            label: "能否出租/使用",

            value: item.lock_state == 1 ? true : false,
            canEdit: true,
            type: "switch"
        }

    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateLeaseItem
    drawOptions.formData = formData
    drawOptions.title = "修改物品型号"
    isOpenDrawer.value = true
}
</script>

<style lang="scss" scoped>
.rebeccapurple {

    font-weight: bold;
    text-align: center;

}

img {
    cursor: pointer;
}
.dddd {
    .flex {
        align-items: center;
        height: 40px;

        span {
            margin-left: 35%;
            width: 100px;
        }
    }
}
.red {

    color: #fff;
    text-align: center;

}
</style>