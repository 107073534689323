<template>
    <div>


        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">租赁物品列表</div>

        <TableComponent :headers="headers" :tableObjet="tableObjet" :psize="5" :height="750">
            <template #deposit="item">
                <div class="red">
                    {{ item.row.deposit }}
                </div>
            </template>
            <template #price="item">
                <div>
                    <div>
                        <el-tag class="rebeccapurple">
                            15天:{{ item.row.day_15_price }}
                        </el-tag>
                    </div>
                    <div>
                        <el-tag class="rebeccapurple">
                            30天:{{ item.row.price }}
                        </el-tag>
                    </div>
                    <div>
                        <el-tag class="rebeccapurple">
                            60天:{{ item.row.day_60_price }}
                        </el-tag>
                    </div>
                    <div>
                        <el-tag class="rebeccapurple">
                            90天:{{ item.row.day_90_price }}
                        </el-tag>
                    </div>
                </div>
            </template>
            <template #d15_price="item">
                <el-tag class="rebeccapurple">
                    {{ item.row.d15_price }}
                </el-tag>
            </template>
            <template #qrcode="item">

                <vue-qr :logoScale="0.2"  @click="fangda(item.row)" :margin="5"
                    :text="'https://jimove.jimoveebikes.com/' + item.row.device_number" :size="100"></vue-qr>



            </template>
            <template #state="item">
                <el-tag>{{ getState(item.row.state) }}</el-tag>
            </template>
            <template #lock_state="item">

                <el-switch :value="item.row.lock_state == 1 ? true : false" disabled></el-switch>
            </template>
            <template #type="item">
                {{ getType(item.row.type) }}
            </template>
            <!-- <template #active_state="item">
                    <el-tag>{{item.row.active_state?'已激活':'未激活'}}</el-tag>
            </template> -->
            <!-- <template #active_code="item">
                <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data='+item.row.active_code" alt="" width="100">
            </template> -->

            <template #print="item">
                <el-button @click="addToPrints(item.row)" circle icon="Printer">

                </el-button>
            </template>
            <template #caozuo="item">
                <div>
                    <div>
                        <a :href="'#/leaseitem/detail?device_number=' + item.row.device_number" target="_blank">
                            <el-button circle type="primary" icon="Memo"></el-button>
                        </a>

                        <el-button @click="handleEdit(item.$index, item.row)" type="primary" icon="Edit" circle  style="margin-left: 12px;"/>
                    </div>
                    <div style="margin-top: 10px;" >
                        <el-button :disabled="item.row.state != 0" type="primary"
                            @click="zhuanyidialogFormVisible = true; leaseItem = item.row" circle icon="SwitchFilled">

                        </el-button>



                        <!-- <el-button :disabled="item.row.lease_count > 0" circle type="danger">
                            <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon-color="#626AEF"
                                title="确认删除此租赁物品?" @confirm="deleteItem(item.row)">
                                <template #reference>
                                    <el-icon color="fff">
                                        <DeleteFilled />
                                    </el-icon>
                                </template>
                            </el-popconfirm>
                        </el-button> -->
                    </div>
                </div>


            </template>
        </TableComponent>

        <el-dialog v-model="dialogFormVisible" title="查看二维码">
            <div id="qrcode">
                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ leaseItem.device_number }}
                    </div>
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>


                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                        :text="'https://jimove.jimoveebikes.com/' + leaseItem.device_number" :size="180"></vue-qr>

                    <div style="margin-top: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ leaseItem.device_car_number }}
                    </div>
                </div>

            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="dayin()">
                        打印
                    </el-button>

                    <el-button type="primary" @click="dialogFormVisible = false">
                        ok
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="zhuanyidialogFormVisible" title="转移设备">
            <div class="dddd">
                <div class="flex">
                    <span> 设备号：</span>
                    <div>{{ leaseItem.device_number }}</div>
                </div>
                <div class="flex">
                    <span>原门店：</span>
                    <div>{{ leaseItem.name }}</div>
                </div>
                <div class="flex">
                    <span>新门店：</span><el-select v-model="store_id" filterable>
                        <el-option v-for="o in searchData.store_id.options" :key="o.value" :label="o.label"
                            :value="o.value" />
                    </el-select>
                </div>
                <div class="flex">
                    <span>车辆型号：</span><el-select v-model="itemModelId" filterable>
                        <el-option v-for="o in itemModelList" :key="o.value" :label="o.label" :value="o.value" />
                    </el-select>
                </div>
                <div class="flex">
                    <span> 计费规则：</span><el-select v-model="billingRuleId" filterable>
                        <el-option v-for="o in billingRuleList" :key="o.value" :label="o.label" :value="o.value" />
                    </el-select>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">



                    <el-button type="primary" @click="moveDevice()">
                        转移
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>

    <div>



        <div
            style="margin: 20px;background-color: #eee;width: 300px;text-align: center;border-radius: 5px;padding: 5px;">
            点击上方<el-button circle icon="Printer"></el-button> 图标,添加到打印队列
        </div>

        <div>

            <el-tag v-for="l in prints" :key="l.id" style="margin-right: 10px;"> {{ l.device_car_number }}</el-tag>
        </div>

        <el-button @click="prints = []">清除</el-button>


        <el-button @click="printAll(180)">小尺寸打印</el-button>
        <el-button @click="printAll(290)">大尺寸打印</el-button>
        <div id="qrcodes">
            <template v-for="l in prints" :key="l.id">
                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ l.device_number }}
                    </div>
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                        :text="'https://jimove.jimoveebikes.com/' + l.device_number" :size="w"></vue-qr>


                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ l.device_car_number }}
                    </div>
                </div>

                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ l.device_number }}
                    </div>
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                        :text="'https://jimove.jimoveebikes.com/' + l.device_number" :size="w"></vue-qr>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ l.device_car_number }}
                    </div>
                </div>
                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ l.device_number }}
                    </div>
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                        :text="'https://jimove.jimoveebikes.com/' + l.device_number" :size="w"></vue-qr>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ l.device_car_number }}
                    </div>
                </div>
                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ l.device_number }}
                    </div>
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                        :text="'https://jimove.jimoveebikes.com/' + l.device_number" :size="w"></vue-qr>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ l.device_car_number }}
                    </div>
                </div>

            </template>

        </div>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useLeaseItemList } from "@/use/leaseitems"
import print from "print-js"
// 导入 jsPDF 库
import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import { reactive, inject, ref, computed, watch } from "vue"
import { updateLeaseItem, moveDeviceForId, deleteItemForId } from "@/api/leaseItem"
import { getItemModelList } from "@/api/itemModel"

import { getBillingRulesOptions } from "@/api/billingrule";
import axios from 'axios';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const dialogFormVisible = ref(false)
const zhuanyidialogFormVisible = ref(false)
const store_id = ref()
const { headers, tableObjet, search, searchData } = useLeaseItemList();
const leaseItem = ref()
const prints = ref([])
const w = ref(180)

const itemModelId = ref("")
const billingRuleId = ref("")
const itemModelList = ref([])
const billingRuleList = ref([])
const deleteItem = (i) => {
    deleteItemForId({ id: i.id }).then(res => {
        if (res.code != 200) {
            ElMessage.error(res.msg)
        } else {
            ElMessage.success("删除成功")
        }
        search()
    })
}
const addToPrints = (i) => {
    const isIn = prints.value.indexOf(i)
    if (isIn < 0) prints.value.push(i)
}

watch(store_id, i => {
    billingRuleId.value = ""

    getBillingRulesOptions(i).then(res => {
        if (res.code == 200) {
            billingRuleList.value = res.data
        }
    })
    itemModelId.value = ""
    getItemModelList({ store_id: i }).then(res => {
        if (res.code == 200) {
            itemModelList.value = res.data.data.map(i => {
                return {
                    label: i.model,
                    value: i.id
                }
            })
        }
    })
})
const printAll = (i) => {
    if (prints.value.length == 0) {
        ElMessage.info("请先选择要打印的二维码")
        return
    }
    w.value = i
    ElMessage.info("正在启动打印")
    let text = i < 200 ? "小尺寸二维码" : "大尺寸二维码"

    window.alert("你选择的是" + text + "打印时先选择好：纸张尺寸和打印边距")
    setTimeout(() => {
        print({
            printable: 'qrcodes',
            type: "html",
            style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

        })
    }, 1000);
}
const dayin = () => {

    // const LODOP = getLodop()

    // LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
    // LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
    // LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
    //LODOP.PREVIEW();
    // LODOP.PRINT();
    // LODOP.PRINT();
    // return
    print({
        printable: 'qrcode',
        type: "html",
        style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

    })
    return
    // print the html string

    // 获取要打印的节点
    const node = document.getElementById('qrcode');

    // 创建一个新的 jsPDF 实例
    const doc = new jsPDF();

    // 将节点的 HTML 内容添加到 PDF 中
    doc.html(node, {
        callback: function () {
            // 调用浏览器的打印对话框
            window.print();
        }
    });
    //window.print()
    // print the dom element
    //const  content= document.getElementById('qrcode')
    //callPrinter(`<img src="https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/080265295"/>22`)
}
const getState = (state) => {

    const states = {
        0: "闲置",
        1: "租赁中"
    };
    return states[state]
}
const fangda = (item) => {
    dialogFormVisible.value = true
    leaseItem.value = item
}
const getType = (type) => {

    const types = {
        1: "按次数",
        3: "次月提现"
    };
    return types[type]
}
const moveDevice = () => {

    if (!store_id.value) {
        ElMessage.error("请选择门店")
        return
    }
    if (!itemModelId.value) {
        ElMessage.error("请选择车辆型号")
        return
    }
    if (!billingRuleId.value) {
        ElMessage.error("请选择收费规则")
        return
    }
    moveDeviceForId({
        billingRuleId: billingRuleId.value,
        itemModelId: itemModelId.value,
        store_id: store_id.value,
        lease_item_id: leaseItem.value.id
    }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            zhuanyidialogFormVisible.value = false
            billingRuleId.value = ""
            itemModelId.value = ""
            store_id.value = ""
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const handleEdit = (index, item) => {
    // if (item.state != 0) {
    //     ElMessage.error("只有闲置中的物品才能修改!")
    //     return
    // }
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",

            value: item.name,
            canEdit: false,
            type: "text"
        },
        device_number: {
            label: "设备号",

            value: item.device_number,
            canEdit: true,
            type: "text"
        },


        device_car_number: {
            label: "车架号",

            value: item.device_car_number,
            canEdit: true,
            type: "text"
        },
        lock_state: {
            label: "能否出租/使用",

            value: item.lock_state == 1 ? true : false,
            canEdit: true,
            type: "switch"
        }

    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateLeaseItem
    drawOptions.formData = formData
    drawOptions.title = "修改物品型号"
    isOpenDrawer.value = true
}
</script>

<style lang="scss" scoped>
.rebeccapurple {

    font-weight: bold;
    text-align: center;

}

img {
    cursor: pointer;
}

.dddd {
    .flex {
        align-items: center;
        height: 40px;

        span {
            margin-left: 35%;
            width: 100px;
        }
    }
}

.red {

    color: #fff;
    text-align: center;

}
</style>